import React from "react";

const Client = () => {
	let privateClient = [
		{
			id: 1,
			imageSrc: "assets/img/clients/private/balaji.png",
		},
		{
			id: 2,
			imageSrc: "assets/img/clients/private/baxter.png",
		},
		{
			id: 3,
			imageSrc: "assets/img/clients/private/camlin.png",
		},
		{
			id: 4,
			imageSrc: "assets/img/clients/private/duravit.png",
		},
		{
			id: 5,
			imageSrc: "assets/img/clients/private/GSPL.png",
		},
		{
			id: 6,
			imageSrc: "assets/img/clients/private/intas.png",
		},
		{
			id: 7,
			imageSrc: "assets/img/clients/private/jswsteel.png",
		},
		{
			id: 8,
			imageSrc: "assets/img/clients/private/posco.png",
		},
		{
			id: 9,
			imageSrc: "assets/img/clients/private/dhartiagro.png",
		},
	];

	let govClient = [
		{
			id: 1,
			imageSrc: "assets/img/clients/gov/ADR.png",
		},
		{
			id: 2,
			imageSrc: "assets/img/clients/gov/CBEC.png",
		},
		{
			id: 3,
			imageSrc: "assets/img/clients/gov/DEMMP.png",
		},
		{
			id: 4,
			imageSrc: "assets/img/clients/gov/DFS.png",
		},
		{
			id: 5,
			imageSrc: "assets/img/clients/gov/ED.png",
		},
		{
			id: 6,
			imageSrc: "assets/img/clients/gov/EDGG.png",
		},
		{
			id: 7,
			imageSrc: "assets/img/clients/gov/FED.png",
		},
		{
			id: 8,
			imageSrc: "assets/img/clients/gov/ITI.png",
		},
		{
			id: 9,
			imageSrc: "assets/img/clients/gov/Ministry-of-Labour.png",
		},
		{
			id: 10,
			imageSrc: "assets/img/clients/gov/NHSRCL.png",
		},
		{
			id: 11,
			imageSrc: "assets/img/clients/gov/SVPNIT.png",
		},
		{
			id: 12,
			imageSrc: "assets/img/clients/gov/WR.png",
		},
	];

	return (
		<section id="clients" className="clients">
			<div className="container" data-aos="zoom-out">
				<div className="section-header">
					<h2>Our Client</h2>
				</div>
				<div className="clients-slider swiper">
					<div className="swiper-wrapper align-items-center">
						{privateClient.map((client) => {
							return (
								<div className="swiper-slide" key={client.id}>
									<img src={client.imageSrc} className="img-fluid" alt="" />
								</div>
							);
						})}
					</div>
				</div>
				<br />
				<div className="clients-slider swiper">
					<div className="swiper-wrapper align-items-center">
						{govClient.map((client) => {
							return (
								<div className="swiper-slide" key={client.id}>
									<img src={client.imageSrc} className="img-fluid" alt="" />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Client;
