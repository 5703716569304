import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Main from "./Components/Main/Main";

function App() {
	return (
		<>
			<Header />
			<Home />
			<Main />
			<Footer />
			<a href="#home" className="scroll-top d-flex align-items-center justify-content-center">
				<i className="bi bi-arrow-up-short"></i>
			</a>

			<div id="preloader"></div>
		</>
	);
}

export default App;
