import React from "react";

const Service = () => {
	let services = [
		{
			id: 1,
			name: "Audio / Video Conferencing",
			iconClass: "bi bi-camera-reels",
			imgSrc: "assets/img/services/audio-video-conferencing.jpg",
			description: "Solutions transmit high quality voice & video with absolute crystal-clear clarity delivering at very competitive prices.",
		},
		// {
		// 	id: 2,
		// 	name: "LAN Connectivity",
		// 	iconClass: "bi bi-ethernet",
		// 	imgSrc: "assets/img/services/Lan.jpg",
		// 	description: "Flexible switching from core to access layer for strengthening security and simplifying your network Solution.",
		// },
		{
			id: 3,
			name: "WI-FI Connectivity",
			iconClass: "bi bi-wifi",
			imgSrc: "assets/img/services/wifi.jpg",
			description:
				"We design the wireless solution as per industry standard by following steps like Survey, Design, Implementation and Post installation Survey.",
		},
		{
			id: 4,
			name: "Structured Cabling",
			iconClass: "bi bi-hdd-network",
			imgSrc: "assets/img/services/structure-cabling.jpg",
			description: "Structured cabling includes design, install, maintain, troubleshoot, and upgrade of your network.",
		},
		{
			id: 5,
			name: "Data Centre & Storage",
			iconClass: "bi bi-database-fill",
			imgSrc: "assets/img/services/data-center.jpg",
			description:
				"We do planning, designing, building, monitoring, maintenance and upgrade of the entire data center for your physical or Virtual infrastructure.",
		},
		// {
		// 	id: 6,
		// 	name: "Server & Storage",
		// 	iconClass: "bi bi-database-fill",
		// 	imgSrc: "assets/img/services/storage-and-server.jpg",
		// 	description:
		// 		"We offer advanced server solutions for businesses that provide high performance, reliability, efficiency, and storage capacity while minimizing costs and ensuring automation, load balancing, and disaster recovery.",
		// },
		{
			id: 7,
			name: "Data Security",
			iconClass: "bi bi-shield-lock",
			imgSrc: "assets/img/services/data-protection.jpg",
			description:
				"We offer smart and comprehensive security solutions that cover entire organizational networks, prevent unauthorized access, and ensure 24x7 protection against evolving threats, supported by on-call assistance.",
		},
		{
			id: 8,
			name: "Fire Alarm",
			iconClass: "bi bi-fire",
			imgSrc: "assets/img/services/fire-alarm.jpg",
			description:
				"We provide fire protection solutions that detect and alert building staff of fire emergencies, signaling the need for evacuation and notifying fire and emergency departments in organization.",
		},
		{
			id: 9,
			name: "PA system",
			iconClass: "bi bi-megaphone",
			imgSrc: "assets/img/services/pa-system.jpeg",
			description:
				"We design and install high-quality PA Systems for clear announcements in noisy and crowded locations, using electronic amplification with loudspeakers, mixers, and amplifiers.",
		},
		{
			id: 10,
			name: "Access control & Time Attendance System",
			iconClass: "bi bi-person-bounding-box",
			imgSrc: "assets/img/services/access-control-system.jpg",
			description:
				"We offer access control solutions that use various credentials like biometric, RFID, and face recognition features to create customized visitor passes and restrict physical access to selected areas.",
		},
		{
			id: 11,
			name: "Computing & Peripherals Components",
			iconClass: "bi bi-motherboard",
			imgSrc: "assets/img/services/computing.jpg",
			description:
				"We offer a complete range of computing solutions, including laptops, desktops, all-in-ones, tablets, and workstations with both standard and customized configurations, as well as upgrade assistance for existing systems.",
		},
		// {
		// 	id: 12,
		// 	name: "Peripherals Components",
		// 	iconClass: "bi bi-hdd-rack",
		// 	imgSrc: "assets/img/services/peripheral.jpg",
		// 	description:
		// 		"We provide a comprehensive range of computer accessories and parts for desktops, laptops, servers, and workstations, including keyboards, mice, headphones, hard drives, SSDs, DVD-RWs, speakers, graphic cards, RAM, and cables.",
		// },
		// {
		// 	id: 13,
		// 	name: "Software Solutions",
		// 	iconClass: "bi bi-window-stack",
		// 	imgSrc: "assets/img/services/software-solution.jpg",
		// 	description:
		// 		"We provide a complete range of software solutions for any computer requirement, including operating systems, antivirus, graphics, design, and more.",
		// },
		{
			id: 14,
			name: "CCTV & Surveillance",
			iconClass: "bi bi-incognito",
			imgSrc: "assets/img/services/cctv.jpg",
			description:
				"We offer surveillance and video analytics systems that use analog/IP technology for digital video output, enabling prevention of robbery, and disasters like fire, and monitoring visitors.",
		},
		{
			id: 15,
			name: "EPBX & IP EPBX",
			iconClass: "bi bi-telephone",
			imgSrc: "assets/img/services/epbx.jpg",
			description:
				"We offer both analog and IP communications systems for voice, video, and data, providing a scalable and secure platform for efficient and effective communication across mobile phones, PCs, video conferencing, and IP phones.",
		},
		{
			id: 16,
			name: "Power Solutions",
			iconClass: "bi bi-power",
			imgSrc: "assets/img/services/ups.jpg",
			description:
				"Ensure uninterrupted technology operation with our range of power solutions, including offline, online, and modular backup devices starting from 600VA.",
		},
	];

	return (
		<section id="services" className="services">
			<div className="container" data-aos="fade-up">
				<div className="section-header">
					<h2>Our Services</h2>
				</div>

				<div className="row gy-5">
					{services.map((service) => {
						return (
							<div key={service.id} className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
								<div className="service-item">
									<div className="img">
										<img src={service.imgSrc} className="img-fluid" alt="" style={{ height: "600", width: "800" }} />
									</div>
									<div className="details position-relative">
										<div className="icon">
											<i className={service.iconClass}></i>
										</div>
										<span className="stretched-link">
											<h3>{service.name}</h3>
										</span>
										<p>{service.description}</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Service;
